// Re-export of ConfigData in sea-map/index above seems not to work,
// so import it directly from here:
import { ConfigData } from "mykomap/app/model/config-schema";
import * as versions from "./version.json";

import { getPopup } from "./popup";

export const config: ConfigData = new ConfigData({
  namedDatasets: ["ica"],
  namedDatasetsVerbose: ["ICA"],
  htmlTitle: "ICA",
  filterableFields: [
    "countryId",
    "superRegionId",
    "regionId",
    "baseMembershipType",
    "regorg",
    "primaryActivity",
  ],
  fields: {
    desc: "value",
    street: "value",
    locality: "value",
    postcode: "value",
    www: "value",
    primaryActivity: {
      type: "vocab",
      uri: "aci:",
    },
    activities: {
      type: "multi",
      from: "activity",
      of: {
        type: "vocab",
        uri: "aci:",
      },
    },
    countryId: {
      type: "vocab",
      uri: "coun:",
    },
    regionId: {
      type: "vocab",
      uri: "reg:",
    },
    superRegionId: {
      type: "vocab",
      uri: "sreg:",
    },
    regorg: {
      type: "vocab",
      uri: "os:",
    },
    qualifier: {
      type: "vocab",
      uri: "qf:",
    },
    baseMembershipType: {
      type: "vocab",
      uri: "bmt:",
    },
  },
  languages: ["EN", "FR", "ES", "KO"],
  language: "EN",

  vocabularies: [
    {
      id: "ica-vocab",
      type: "hostSparql",
      label: "ICA",
      endpoint: "http://dev.data.solidarityeconomy.coop:8890/sparql",
      defaultGraphUri: "https://dev.lod.coop/ica",
      uris: {
        "https://dev.lod.coop/essglobal/2.1/standard/activities-ica/": "aci",
        "https://dev.lod.coop/essglobal/2.1/standard/countries-iso/": "coun",
        "https://dev.lod.coop/essglobal/2.1/standard/regions-ica/": "reg",
        "https://dev.lod.coop/essglobal/2.1/standard/super-regions-ica/":
          "sreg",
        "https://dev.lod.coop/essglobal/2.1/standard/organisational-structure/":
          "os",
        "https://dev.lod.coop/essglobal/2.1/standard/qualifiers/": "qf",
        "https://dev.lod.coop/essglobal/2.1/standard/base-membership-type/":
          "bmt",
      },
    },
  ],
  dataSources: [
    {
      id: "ica",
      type: "hostSparql",
      label: "ICA",
    },
  ],

  tileUrl:
    "https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=bb1cf9a95944474da77e513385d19680",
  mapAttribution:
    "Maps &copy; <a href='http://www.thunderforest.com'>Thunderforest</a> & &copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap contributors</a> | Other data <a href='http://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a> | Powered by <a href='https://www.geoapify.com/'>Geoapify</a> | This map contains indications of areas where there are disputes over territories. The ICA does not endorse or accept the boundaries depicted on the map.",
  defaultLatLng: [50.84999, 4.39434],
  initialBounds: [
    [62, -107],
    [-37, 174],
  ],
  maxZoomOnOne: 10,

  showDirectoryPanel: true,
  showSearchPanel: true,
  showAboutPanel: true,
  showDatasetsPanel: false,
  defaultOpenSidebar: true,
  logo: "logo.png",
  sidebarButtonColour: "#8E0B56",
  customPopup: getPopup,
  ...versions,
});
